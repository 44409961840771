import React from "react"
import PropTypes from "prop-types"
import { kebabCase } from "lodash"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share"

const FacebookIconBS = (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fab"
    data-icon="facebook-f"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
    className="w-4 h-4"
  >
    <path
      fill="currentColor"
      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
      className=""
    ></path>
  </svg>
)
const TwitterIconBS = (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fab"
    data-icon="twitter"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className="w-4 h-4"
  >
    <path
      fill="currentColor"
      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
      className=""
    ></path>
  </svg>
)

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  const { edges: posts } = data.allMarkdownRemark

  let { slug } = post.fields
  let { title } = post.frontmatter
  let { featuredimage } = post.frontmatter
  const siteurl = "https://msdncnews.org"

  let authorLabel = null
  switch (post.frontmatter.author) {
    case "dkrasniy":
      authorLabel = "David K"
      break
    case "hunter_penn_h":
      authorLabel = "Hunter P"
      break
    default:
      authorLabel = "MSDNC NEWS"
  }

  return (
    <Layout>
       <Helmet
          title={post.frontmatter.title}
          titleTemplate={`%s`}
          meta={[
            {
              property: "og:title",
              content: post.frontmatter.title,
            },
            {
              property: "twitter:title",
              content: post.frontmatter.title,
            },
            {
              property: "twitter:description",
              content: post.frontmatter.description,
            },

            {
              name: "description",
              content: post.frontmatter.title,
            },
            {
              property: "og:image",
              content:
                "https://msdncnews.org" +
                post.frontmatter.featuredimage.childImageSharp.fixed.src,
            },
            {
              property: "twitter:image",
              content:
                "https://msdncnews.org" +
                post.frontmatter.featuredimage.childImageSharp.fixed.src,
            },

            {
              property: "og:image:url",
              content:
                "https://msdncnews.org" +
                post.frontmatter.featuredimage.childImageSharp.fixed.src,
            },
            {
              property: "og:type",
              content: "website",
            },
            {
              name: "twitter:card",
              content: "summary_large_image",
            },
            {
              name: "twitter:creator",
              content: "dkrasniy",
            },
          ]}
        />

      <article itemScope itemType="http://schema.org/Article">
        <div className="py-8 px-4 pb-2">
          <div className="max-w-2xl mx-auto">
            <h1
              itemProp="name headline"
              className="leading-tight text-gray-900 text-2xl md:text-3xl camber font-semibold"
            >
              {title}
            </h1>
            {/* {post.frontmatter.description ? <span className="block lora text-gray-700 py-1 text-sm md:text-base">{post.frontmatter.description}</span>: null} */}
            <div className="py-2 flex items-center justify-between mt-2 pt-2">
              <div className="flex items-center md:mb-0">
                {/* <a className="font-semibold" href={`https://twitter.com/${post.frontmatter.author}`} title={`Twitter account for ${authorLabel}`} target="_blank">
                
                <img className="w-10 rounded-full mr-3" src={`https://avatars.io/twitter/${post.frontmatter.author}/medium`} alt={`Twitter profile image for ${authorLabel}`}/></a> */}
                <div className="text-gray-700  text-xs">
                  <span className="block">
                    By{" "}
                    <span
                      itemprop="author"
                      itemscope
                      itemtype="http://schema.org/Person"
                    >
                      <a
                        href={`https://twitter.com/${post.frontmatter.author}`}
                        itemprop="url"
                        title={`Twitter account for ${authorLabel}`}
                        target="_blank"
                        className="uppercase text-xs tracking-wide font-medium"
                      >
                        <span itemprop="name">{authorLabel}</span>
                      </a>
                    </span>
                  </span>
                  <time
                    itemProp="datePublished"
                  >
                    <span>Published on</span>{" "}
                    <span className="uppercase text-xs tracking-wide font-medium">{post.frontmatter.date}</span>
                  </time>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      
        <div className="max-w-2xl leading-loose mx-auto py-4 px-4 md:px-0">
           <div className="max-w-2xl mx-auto">
            {featuredimage ? (
              <Img
                fluid={
                  featuredimage &&
                  featuredimage.childImageSharp &&
                  featuredimage.childImageSharp.fluid
                }
              />
            ) : null}
          </div> 
         
          <div
            className="article-msdnc py-6 pb-2 lora"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
         

          {post.frontmatter.tags && post.frontmatter.tags.length ? (
            <div>
              <h4 className="uppercase text-xs font-semibold tracking-wide pl-2">Tags</h4>
              <ul className="taglist flex" style={{ listStyle: "none" }}>
                {post.frontmatter.tags.map(tag => (
                  <li key={tag + `tag`} className="bg-gray-100 rounded-full px-3 py-1 text-xs mr-2">
                    <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
        <div className="max-w-2xl mx-auto bg-white p-6 py-1 my-4 block container mx-auto">
          <div className=" my-8">
            <span className="block font-semibold text-center text-lg pb-2">
              Share this story
            </span>
            <div className="flex text-center justify-center">
              <TwitterShareButton
                style={{ background: "#1DA1F2" }}
                url={siteurl + slug}
                resetButtonStyle={false}
                title={title}
                className="twitter-bg rounded-full text-sm text-white outline-none focus:outline-none p-2 px-4 mr-2 flex items-center"
               
              >
                {TwitterIconBS}
                <span className="ml-2">Share to Twitter</span>
              </TwitterShareButton>
              <FacebookShareButton
                style={{ background: "#4267B2" }}
                resetButtonStyle={false}
                className="fb-bg rounded-full text-sm text-white outline-none focus:outline-none p-2 px-4 mr-2 flex items-center"
                url={siteurl + slug}
                quote={title}
              >
                {FacebookIconBS}
                <span className="ml-2">Share to Facebook</span>
              </FacebookShareButton>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        tags
        author
        description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 800, height: 420, quality: 80) {
              width
              height
              src
              srcSet
              srcWebp
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: 11
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
